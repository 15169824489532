require("@rails/ujs").start()
require("../src/application/toggle-controls")

import "../stylesheets/home-search.css.sass"
import ReactOnRails from "react-on-rails"

import MobileMenu from "../components/header/MobileMenu"
import ProProfile from "../pages/ProProfile"
import ProProfileBookableEventTypePage from "../pages/ProProfileBookableEventTypePage/ProProfileBookableEventTypePage"
import ProProfileServicePage from "../pages/ProProfileServicePage/ProProfileServicePage"
import ProProfileThemeFive from "../pages/ProProfileThemeFive/ProProfileThemeFive"
import ProProfileThemeFour from "../pages/ProProfileThemeFour/ProProfileThemeFour"
import ProProfileThemeOne from "../pages/ProProfileThemeOne/ProProfileThemeOne"
import ProProfileThemeThree from "../pages/ProProfileThemeThree/ProProfileThemeThree"
import ProProfileThemeTwo from "../pages/ProProfileThemeTwo/ProProfileThemeTwo"
import ProProfileV2 from "../pages/ProProfileV2"
// eslint-disable-next-line no-unused-vars
import dayJsPlugins from "../utils/dayJsPlugins"

const components = {
  ProProfile,
  ProProfileV2,
  ProProfileThemeOne,
  ProProfileThemeTwo,
  ProProfileThemeThree,
  ProProfileThemeFour,
  ProProfileThemeFive,
  MobileMenu,
  ProProfileServicePage,
  ProProfileBookableEventTypePage
}

ReactOnRails.register(components)
