import dayjs from "dayjs"
import React, { useState, useEffect } from "react"

const BookingSection = ({ bookableEvents, practice }) => {
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [eventsByDate, setEventsByDate] = useState({})

  // Group events by date
  useEffect(() => {
    if (!bookableEvents?.length) return

    const groupedEvents = bookableEvents.reduce((acc, event) => {
      const date = dayjs(event.startsAt).format("YYYY-MM-DD")
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(event)
      return acc
    }, {})

    setEventsByDate(groupedEvents)

    // Set initial selected date to the first available date
    if (Object.keys(groupedEvents).length > 0 && !selectedDate) {
      setSelectedDate(Object.keys(groupedEvents)[0])
    }
  }, [bookableEvents, selectedDate])

  const handleDateSelect = (date) => {
    setSelectedDate(date)
    setSelectedEvent(null)
  }

  const handleEventSelect = (event) => {
    setSelectedEvent(event)
  }

  return (
    <div className="mt-12">
      {Object.keys(eventsByDate).length > 0 ? (
        <div>
          {/* Date Selection */}
          <h3 className="mb-4 text-xl font-semibold">Select Date</h3>
          <div className="mb-8 grid grid-cols-3 gap-3 md:grid-cols-1">
            {Object.keys(eventsByDate).map((date) => (
              <button
                key={date}
                className={`rounded-lg border p-3 text-center transition-colors ${
                  selectedDate === date ? "border-teal bg-gray-ultralight text-teal" : "border-gray hover:border-teal"
                }`}
                onClick={() => handleDateSelect(date)}>
                {dayjs(date).format("ddd, MMM D")}
              </button>
            ))}
          </div>

          {/* Time Selection */}
          {selectedDate && (
            <div>
              <h3 className="mb-4 text-xl font-semibold">Select Time</h3>
              <div className="grid grid-cols-3 gap-3 md:grid-cols-1">
                {eventsByDate[selectedDate].map((event) => {
                  const startTime = dayjs(event.startsAt).format("h:mm A")
                  const endTime = dayjs(event.endsAt).format("h:mm A")
                  const timeDisplay = event.allDay ? "All day" : `${startTime} - ${endTime}`

                  return (
                    <button
                      key={event.id}
                      className={`rounded-lg border p-3 text-center transition-colors ${
                        selectedEvent?.id === event.id
                          ? "border-teal bg-gray-ultralight text-teal"
                          : "border-gray hover:border-teal"
                      }`}
                      onClick={() => handleEventSelect(event)}>
                      <div>{timeDisplay}</div>
                      {event.spotsAvailable > 0 && (
                        <div className="mt-1 text-xs text-teal">
                          {event.spotsAvailable} {event.spotsAvailable === 1 ? "spot" : "spots"} left
                        </div>
                      )}
                    </button>
                  )
                })}
              </div>
            </div>
          )}

          {/* Selected Event Details */}
          {selectedEvent && (
            <div className="mt-6">
              {/* Booking Button */}
              <div className="mt-4">
                <button
                  className="w-auto rounded-lg bg-teal px-6 py-3 font-semibold text-white transition-colors hover:bg-teal-dark md:w-full"
                  onClick={() => {
                    const practiceId = practice.id
                    window.location.href = `/checkout?pid=${practiceId}&beid=${selectedEvent.id}`
                  }}>
                  Go to checkout
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="rounded-lg border border-gray-light bg-gray-ultralight p-6 text-center">
          <p className="text-gray">No available events at this time.</p>
        </div>
      )}
    </div>
  )
}

export default BookingSection
