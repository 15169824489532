export const bookableEventTypeDisplayAmount = (bookableEventType, currency) => {
  if (bookableEventType.amountCents === 0) {
    return "Free"
  }

  if (currency === "USD") {
    return `$${bookableEventType.amountCents / 100}`
  }

  return `${bookableEventType.amountCents / 100} ${currency}`
}
