import React from "react"
import { twMerge } from "tailwind-merge"

import { bookableEventTypeDisplayAmount } from "../../@core/bookableEventType/bookableEventType.utils"
import AnimatingBackgroundImage from "../../components/shared/AnimatingBackgroundImage"
import { ToastProvider, useToast } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"
import AnimatedSection from "../ProProfileShared/AnimatedSection"
import BottomCTA from "../ProProfileShared/BottomCTA"
import ChatWidget from "../ProProfileShared/ChatWidget"
import Footer from "../ProProfileShared/Footer"
import Map from "../ProProfileShared/Map"
import Navigation from "../ProProfileShared/Navigation"
import ThemeFiveNavigation from "../ProProfileThemeFive/ThemeFiveNavigation"

import BookingSection from "./BookingSection"

const ProProfileBookableEventTypePage = ({ practice, bookableEventType, currentUser, bookableEvents }) => {
  const toast = useToast()

  const theme = practice.theme
  const themeName = theme.name
  const url = practice.headerHeroImageUrl.replace(/\+/g, "%20").replace(/\(/g, "%28").replace(/\)/g, "%29")

  const bookableEventTypeHasImage = Boolean(
    bookableEventType.filestackPhoto && Object.keys(bookableEventType.filestackPhoto).length > 0
  )

  const NavigationComponent = () => {
    if (themeName === "vitality") {
      return <ThemeFiveNavigation practice={practice} theme={theme} />
    }

    if (themeName === "serenity") {
      return <Navigation practice={practice} theme={theme} color={theme.colorTextDark} />
    }

    return <Navigation practice={practice} theme={theme} />
  }

  return (
    <GraphQLProvider>
      <ToastProvider>
        <main className="md:overflow-x-hidden">
          <div>
            <AnimatingBackgroundImage
              id="hero-section"
              animationDuration={350}
              url={url}
              className={twMerge("relative flex min-h-[300px] items-center justify-center text-center")}
              style={{ color: theme.colorTextLight }}>
              <AnimatedSection className="container z-10 mx-auto delay-[350ms] md:p-8">
                <h1
                  className={twMerge("text-[56px] leading-[72px] lg:text-4xl lg:leading-[48px]")}
                  style={{
                    fontFamily: theme.fontHeading,
                    fontWeight: theme.fontHeadingWeight,
                    color: theme.colorTextLight
                  }}>
                  {bookableEventType.name}
                </h1>
              </AnimatedSection>
              <NavigationComponent />
              <div className="absolute inset-0 h-full w-full bg-black-real/40" />
            </AnimatingBackgroundImage>
          </div>
          <div className="container mx-auto px-4 py-12">
            <div className="grid grid-cols-1 gap-8 min_md:grid-cols-3">
              {/* Left column with service details */}
              <div className="min_md:col-span-2">
                <h2 className="mb-4 text-3xl font-bold">{bookableEventType.name}</h2>
                <div className="mb-4 flex items-center gap-2">
                  <div className="my-1">
                    {bookableEventTypeDisplayAmount(bookableEventType, practice.defaultCurrency)}
                  </div>
                </div>
                <div
                  className="wysiwyg-content mb-4 mt-2 pb-4"
                  dangerouslySetInnerHTML={{ __html: bookableEventType.description }}
                />
              </div>

              {/* Right column with service image */}
              <div>
                {bookableEventTypeHasImage && (
                  <img
                    src={`https://cdn.filestackcontent.com/${bookableEventType.filestackPhoto.original.any}`}
                    alt={bookableEventType.name}
                    className="w-full rounded-lg object-cover"
                  />
                )}
              </div>
            </div>

            {/* Booking Section */}
            <BookingSection bookableEventType={bookableEventType} practice={practice} bookableEvents={bookableEvents} />
          </div>
          <BottomCTA practice={practice} theme={theme} />
          <Map practice={practice} />
          <Footer practice={practice} theme={theme} />
          <ChatWidget practice={practice} currentUser={currentUser} toast={toast} />
        </main>
      </ToastProvider>
    </GraphQLProvider>
  )
}

export default ProProfileBookableEventTypePage
