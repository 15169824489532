export const centsToDollars = (cents) => (cents === 0 ? "Free" : `$${cents / 100}`)

export const serviceDisplayAmount = (service) => {
  const variationsSortedByPrice = service.variationsWithParent.sort((a, b) => a.amountCents - b.amountCents)
  const prices = `${centsToDollars(variationsSortedByPrice[0].amountCents)}-${centsToDollars(
    variationsSortedByPrice[variationsSortedByPrice.length - 1].amountCents
  )}`
  return service.variationsWithParent.length > 1 ? prices : centsToDollars(service.amountCents)
}

export const serviceDisplayTimeLength = (service) => {
  const variationsSortedByLength = service.variationsWithParent.sort((a, b) => a.timeLength - b.timeLength)
  const lengths = `${variationsSortedByLength[0].timeLength}-${
    variationsSortedByLength[variationsSortedByLength.length - 1].timeLength
  }`
  return (service.variationsWithParent.length > 1 ? lengths : service.timeLength) + " min"
}
