import React, { useState, useEffect } from "react"

import { centsToDollars } from "../../@core/service/service.utils"
import { RadioWithLabel } from "../../components/shared/RadioButtons"

import ServiceAvailability from "./ServiceAvailability"

const BookingSection = ({ service, practice }) => {
  const [selectedLocation, setSelectedLocation] = useState(service.locations?.[0] || null)
  const [selectedVariation, setSelectedVariation] = useState(service.variationsWithParent?.[0] || null)
  const [showAvailability, setShowAvailability] = useState(false)

  useEffect(() => {
    if (selectedLocation && (!service.variationsWithParent?.length || selectedVariation)) {
      setShowAvailability(true)
    } else {
      setShowAvailability(false)
    }
  }, [selectedLocation, selectedVariation, service.variationsWithParent])

  return (
    <div className="mt-12">
      {/* Location Selection */}
      <div className="mb-8">
        <h3 className="mb-4 text-xl font-semibold">Select Location</h3>
        <div className="flex flex-col gap-4">
          {service.locations?.map((location) => (
            <RadioWithLabel
              key={location.id}
              name="location"
              label={location.name}
              checked={selectedLocation?.id === location.id}
              onChange={() => setSelectedLocation(location)}
            />
          ))}
        </div>
      </div>

      {/* Variation Selection - Only show if variations exist */}
      {service.variationsWithParent?.length > 0 && (
        <div className="mb-8">
          <h3 className="mb-4 text-xl font-semibold">Select Duration</h3>
          <div className="flex flex-col gap-4">
            {service.variationsWithParent.map((variation) => (
              <RadioWithLabel
                key={variation.id}
                name="variation"
                label={`${variation.timeLength} minutes - ${centsToDollars(variation.amountCents)}`}
                checked={selectedVariation?.id === variation.id}
                onChange={() => setSelectedVariation(variation)}
              />
            ))}
          </div>
        </div>
      )}

      {/* Availability Section */}
      {showAvailability && (
        <ServiceAvailability
          service={service}
          selectedLocation={selectedLocation}
          selectedVariation={selectedVariation}
          practice={practice}
        />
      )}
    </div>
  )
}

export default BookingSection
