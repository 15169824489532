import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import { Button } from "../../components/shared/Buttons"
import CheckBox from "../../components/shared/CheckBox"
import ExpandableText from "../../components/shared/ExpandableText"
import { AnimatedModal } from "../../components/shared/Modal"

const ServiceAddOnModal = ({ visible, onClose, service, addOns, onCheckout }) => {
  const [selectedAddOns, setSelectedAddOns] = useState([])
  const [totalCost, setTotalCost] = useState(0)

  useEffect(() => {
    // Calculate initial cost (just the main service)
    calculateTotalCost([])
  }, [service])

  const toggleAddOn = (addOn) => {
    const currentSelected = [...selectedAddOns]
    const index = currentSelected.findIndex((item) => item.id === addOn.id)

    if (index === -1) {
      // Add the add-on
      currentSelected.push(addOn)
    } else {
      // Remove the add-on
      currentSelected.splice(index, 1)
    }

    setSelectedAddOns(currentSelected)
    calculateTotalCost(currentSelected)
  }

  const calculateTotalCost = (selected) => {
    const mainServiceCost = service.amountCents / 100
    const addOnsCost = selected.reduce((sum, addOn) => sum + addOn.amountCents / 100, 0)
    setTotalCost(mainServiceCost + addOnsCost)
  }

  const handleGoToCheckout = () => {
    // Create array of service IDs (main service first, then selected add-ons)
    const serviceIds = [service.id, ...selectedAddOns.map((addOn) => addOn.id)]
    onCheckout(serviceIds)
  }

  return (
    <AnimatedModal visible={visible} hideModal={onClose} header="Select optional add-ons" showFooter={false}>
      <div className="flex flex-col gap-4">
        {addOns.map((addOn) => (
          <div key={addOn.id} className="flex flex-col items-start border-b border-gray-light pb-4">
            <CheckBox
              id={addOn.id}
              name={`${addOn.name} - Add $${(addOn.amountCents / 100).toFixed(2)}`}
              handleClick={() => toggleAddOn(addOn)}
              checked={selectedAddOns.some((item) => item.id === addOn.id)}
              className="mr-4"
            />
            <ExpandableText text={addOn.description} maxLength={60} className="ml-9 text-sm text-gray-dark" allowHtml />
          </div>
        ))}

        <div className="mt-6">
          <div className="flex justify-between text-gray-dark">
            <span>{service.name}</span>
            <span>${(service.amountCents / 100).toFixed(2)}</span>
          </div>

          {selectedAddOns.map((addOn) => (
            <div key={addOn.id} className="flex justify-between text-gray-dark">
              <span>{addOn.name}</span>
              <span>${(addOn.amountCents / 100).toFixed(2)}</span>
            </div>
          ))}

          <div className="my-2 flex justify-between pt-2 font-semibold">
            <span>Total Cost</span>
            <span>${totalCost.toFixed(2)}</span>
          </div>
        </div>

        <div className="mt-6">
          <Button type="primary" className="w-full" size="large" onClick={handleGoToCheckout}>
            Go to checkout
          </Button>
        </div>
      </div>
    </AnimatedModal>
  )
}

ServiceAddOnModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  addOns: PropTypes.array.isRequired,
  onCheckout: PropTypes.func.isRequired
}

export default ServiceAddOnModal
