import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import React, { useState, useEffect } from "react"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

import { Button } from "../../components/shared/Buttons"
import { Banner } from "../../components/shared/Layout"
import { Link } from "../../components/shared/Links"
import { urqlClient } from "../../utils/utils"

import ServiceAddOnModal from "./ServiceAddOnModal"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

const AVAILABILITY_QUERY = `
  query Availability($serviceId: ID!, $locationId: ID!, $page: Int) {
    bookingAvailability(serviceId: $serviceId, locationId: $locationId, page: $page) {
      availableSlots {
        date
        datetimes
      }
      lastDateInPage
    }
  }
`

const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone
const DEFAULT_TIMEZONE_ABBR = new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]

const DayAvailabilitySkeleton = () => (
  <div className="mb-8">
    <Skeleton className="mb-4 h-6 w-48" />
    <div className="grid grid-cols-3 gap-3 min_sm:grid-cols-5">
      {Array(10)
        .fill()
        .map((_, i) => (
          <Skeleton key={i} className="h-12 w-full rounded-md" />
        ))}
    </div>
  </div>
)

const ServiceAvailability = ({ service, selectedLocation, selectedVariation, practice }) => {
  const [data, setData] = useState(null)
  const [fetching, setFetching] = useState(true)
  const [page, setPage] = useState(0)
  const [daysShown, setDaysShown] = useState(10)
  const [selectedSlot, setSelectedSlot] = useState(null)
  const [error, setError] = useState(null)
  const [retry, setRetry] = useState(0)
  const [showAddOnModal, setShowAddOnModal] = useState(false)

  const timeZone = !selectedLocation
    ? DEFAULT_TIMEZONE
    : selectedLocation.kind === "virtual"
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : selectedLocation.formattedTimeZone

  const timeZoneAbbr = !selectedLocation
    ? DEFAULT_TIMEZONE_ABBR
    : selectedLocation.kind === "virtual"
    ? new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]
    : selectedLocation.timeZoneAbbr

  // Reset the availability when the location or variation changes
  useEffect(() => {
    setData(null)
    setPage(0)
    setDaysShown(10)
    setSelectedSlot(null)
  }, [selectedLocation?.id, selectedVariation?.id])

  // Fetch availability when the location or variation changes
  useEffect(() => {
    const fetchAvailability = async () => {
      setFetching(true)
      setError(null)
      try {
        const response = await urqlClient
          .query(
            AVAILABILITY_QUERY,
            {
              serviceId: selectedVariation?.id || service.id,
              locationId: selectedLocation.id,
              page
            },
            {
              requestPolicy: "network-only"
            }
          )
          .toPromise()

        if (response.error) {
          throw new Error(response.error.message || "Failed to fetch availability")
        }

        const newSlots = response.data.bookingAvailability.availableSlots
        setData((prevData) => ({
          bookingAvailability: {
            availableSlots:
              page === 0 ? newSlots : [...(prevData?.bookingAvailability?.availableSlots || []), ...newSlots],
            lastDateInPage: response.data.bookingAvailability.lastDateInPage
          }
        }))
      } catch (err) {
        console.error("Error fetching availability:", err)
        setError(err.message || "Failed to load availability. Please try again later.")
      }
      setFetching(false)
    }

    if (selectedLocation) {
      fetchAvailability()
    }
  }, [selectedLocation?.id, selectedVariation?.id, service.id, page, retry])

  const availableSlots = data?.bookingAvailability?.availableSlots?.filter((day) => day.datetimes.length > 0) || []
  const lastDateInPage = data?.bookingAvailability?.lastDateInPage

  // Handler to set the selected slot and navigate to checkout
  const handleSlotClick = (time) => {
    setSelectedSlot(time)

    // Check if service has add-ons
    if (service.addOns && service.addOns.length > 0) {
      // Show add-on selection modal
      setShowAddOnModal(true)
    } else {
      // No add-ons, redirect to checkout directly
      redirectToCheckout(time, [selectedVariation ? selectedVariation.id : service.id])
    }
  }

  // Function to handle checkout redirection
  const redirectToCheckout = (time, serviceIds) => {
    const serviceParam = serviceIds.join(",")
    const checkoutUrl = `/checkout?pid=${practice.id}&sid=${serviceParam}&lid=${
      selectedLocation.id
    }&st=${encodeURIComponent(time)}`
    window.location.href = checkoutUrl
  }

  // Handler for when the user completes add-on selection and proceeds to checkout
  const handleAddOnCheckout = (serviceIds) => {
    redirectToCheckout(selectedSlot, serviceIds)
  }

  return (
    <>
      <h3 className="mb-6 text-xl font-semibold">Select a Date and Time ({timeZoneAbbr})</h3>

      {error && (
        <Banner type="error">
          <p className="flex items-center">{error}</p>
          <Link
            onClick={() => {
              setError(null)
              setPage(0)
              setData(null)
              setFetching(true)
              setRetry((prev) => prev + 1)
            }}>
            Try again
          </Link>
        </Banner>
      )}

      {/* Add-on selection modal */}
      <ServiceAddOnModal
        visible={showAddOnModal}
        onClose={() => setShowAddOnModal(false)}
        service={selectedVariation || service}
        addOns={service.addOns || []}
        onCheckout={handleAddOnCheckout}
      />

      {fetching && !data ? (
        <>
          {Array(5)
            .fill()
            .map((_, i) => (
              <DayAvailabilitySkeleton key={i} />
            ))}
        </>
      ) : (
        <div className="mb-8">
          {availableSlots.slice(0, daysShown).map((day) => {
            const dayDate = dayjs(day.date)
            const isToday = dayjs().isSame(dayDate, "day")
            const isTomorrow = dayjs().add(1, "day").isSame(dayDate, "day")

            return (
              <div key={day.date} className="mb-8">
                <h4 className="mb-4 text-lg font-medium">
                  {isToday
                    ? `Today, ${dayDate.tz(timeZone).format("MMMM D")}`
                    : isTomorrow
                    ? `Tomorrow, ${dayDate.tz(timeZone).format("MMMM D")}`
                    : dayDate.tz(timeZone).format("dddd, MMMM D")}
                </h4>
                <div className="grid grid-cols-3 gap-3 min_sm:grid-cols-5">
                  {day.datetimes.map((time) => (
                    <Button
                      key={time}
                      type="secondary"
                      size="large"
                      onClick={() => handleSlotClick(time)}
                      className={`w-full rounded-md border border-teal text-center text-lg transition-colors
                        ${selectedSlot === time ? "bg-teal text-white" : "text-teal hover:bg-teal/50"}`}>
                      {dayjs(time).tz(timeZone).format("h:mma")}
                    </Button>
                  ))}
                </div>
              </div>
            )
          })}

          {fetching ? (
            <div className="flex justify-center">
              <div className="h-8 w-8 animate-spin rounded-full border-4 border-teal border-t-transparent" />
            </div>
          ) : (
            <>
              {lastDateInPage && availableSlots.length === 0 && (
                <p className="text-gray-dark">No available slots through {dayjs(lastDateInPage).format("MMMM D")}</p>
              )}
              <button
                className="mt-4 text-teal underline"
                onClick={() => {
                  setDaysShown(daysShown + 10)
                  if (daysShown >= availableSlots.length) {
                    setPage(page + 1)
                  }
                }}>
                Show more times
              </button>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default ServiceAvailability
